import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import styles from './styles/Home.module.css';

export const showActiveProposals = false; // Initial value
export const setShowActiveProposals = (value) => {
  // Update the value as needed
};

function ProposalComponent({ proposal, voteOnProposal, countdowns }) {
    const [voteAmount, setVoteAmount] = useState('');
    const [voteOption, setVoteOption] = useState('');
    const [isProposalActive, setIsProposalActive] = useState(false);

    // Function to format the remaining time
    const formatTime = (seconds) => {
        const days = Math.floor(seconds / (3600 * 24));
        const hrs = Math.floor((seconds % (3600 * 24)) / 3600);
        const mnts = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${days} days, ${hrs} hours, ${mnts} minutes, ${secs} seconds`;
    };

    // Get the remaining time for this proposal
    const remainingTime = countdowns[proposal.proposalId]?.remainingTime || 0;

    useEffect(() => {
        setIsProposalActive(remainingTime > 0);
    }, [remainingTime]);

    const yesVotesInEther = parseFloat(ethers.utils.formatEther(proposal.yesVotes)).toFixed(0);
    const noVotesInEther =  parseFloat(ethers.utils.formatEther(proposal.noVotes)).toFixed(0);
    const proposalCardClass = isProposalActive 
    ? `${styles.proposalCard} ${styles.active}` // Active state
    : `${styles.proposalCard} ${styles.inactive}`; // Inactive state

    return (
        <div className={proposalCardClass}>
            <h3>Proposal {proposal.proposalId}</h3>
            <p className={styles.proposalDescription}>{proposal.description}</p>
    
            {isProposalActive && (
                <p className={styles.timeRemaining}>Time Remaining: {formatTime(remainingTime)}</p>
            )}
            {!isProposalActive && <p className={styles.proposalEnded}>Proposal has ended</p>}
            
            <p className={styles.votes}>
                Yes <span className={styles.yesVotes}>{yesVotesInEther}</span> - 
                No <span className={styles.noVotes}>{noVotesInEther}</span>
            </p>    
            {isProposalActive && (
                <>
                    <input
                        type="text"
                        placeholder="Vote amount (GPHX)"
                        value={voteAmount}
                        onChange={(e) => setVoteAmount(e.target.value)}
                    />
                    <select
                        value={voteOption}
                        onChange={(e) => setVoteOption(e.target.value)}
                    >
                        <option value="">Select vote option</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    <button onClick={() => voteOnProposal(proposal.proposalId, voteAmount, voteOption)}>
                        Vote Now
                    </button>
                </>
            )}
        </div>
    );
}

export default ProposalComponent;

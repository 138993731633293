import React from 'react';
import { Link } from 'react-router-dom';
import styles from './HomePage.module.css'; // Import your CSS module here
import GOLD from './gold3.jpg';
import GPHX from './GPHX.png';

const HomePage = () => {
  return (
    <div className={styles.homePage} style={{ backgroundImage: `url(${GOLD})` }}> {/* Use styles from CSS module */}
      <img src={GPHX} alt="GPHX Logo" className={styles.logo2} />
      <div className={styles.contentContainer}>
        <h1>Welcome to Governance Phoenix Dao</h1>
        <h2>Notice: 1 GPHX = 1 Vote. Your Token will remain locked for the duration of the proposal.</h2>
        <div className={styles.linkContainer}> {/* Container for the links */}
          <Link to="/dao" className={styles.daoLink}>Proposals</Link>
          <Link to="/chat" className={styles.chatLink}>Chat Room</Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

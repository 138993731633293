import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { ConnectWallet, useContract, useAddress } from "@thirdweb-dev/react";
import GPHX_ABI from "./GPHX.json";
import GPHXDAO_ABI from "./GPHXDAO.json";
import styles from './styles/Home.module.css';
import { useMemo } from "react";
import ProposalComponent, {
    showActiveProposals,
    setShowActiveProposals,
  } from './ProposalComponent';
import { Link } from 'react-router-dom';
import GPHX from './GPHX2.png';
import GOLD from './gold3.jpg';
const GPHX_ADDRESS = "0x163E8183446580D5f48bd25eDeE132dFED9D60c9";
const GPHXDAO_ADDRESS = "0x15c48798e3f252a2027D0788689DA8292793C9A1";

export default function Home() {
    const [gphxTokenContract, setGphxTokenContract] = useState(null);
    const [gphxDaoContract, setGphxDaoContract] = useState(null);
    const [adminAddress, setAdminAddress] = useState(null);
    const userAddress = useAddress();
    const [tokenBalance, setTokenBalance] = useState("0");
    const [proposals, setProposals] = useState([]);
    const [newProposalDescription, setNewProposalDescription] = useState("");
    const [updateProposalId, setUpdateProposalId] = useState('');
    const [updateDuration, setUpdateDuration] = useState('');
    const [countdowns, setCountdowns] = useState({});
    const [selectedProposalId, setSelectedProposalId] = useState("");
    const [unstakeAmount, setUnstakeAmount] = useState("");
    const [unstakeProposalId, setUnstakeProposalId] = useState("");
    const [addressToCheck, setAddressToCheck] = useState('');
    const [proposalIdToCheck, setProposalIdToCheck] = useState('');
    const [stakedTokens, setStakedTokens] = useState('');
    const tokenContract = useContract(GPHX_ADDRESS, GPHX_ABI);
    const DAOContract = useContract(GPHXDAO_ADDRESS, GPHXDAO_ABI);
    const [showActiveProposals, setShowActiveProposals] = useState(true);
    const filteredAndSortedProposals = useMemo(() => {
        return [...proposals]
            .filter(proposal => showActiveProposals ? proposal.active : !proposal.active)
            .sort((a, b) => b.proposalId - a.proposalId);
    }, [proposals, showActiveProposals]);

    useEffect(() => {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            setGphxTokenContract(new ethers.Contract(GPHX_ADDRESS, GPHX_ABI, signer));
            setGphxDaoContract(new ethers.Contract(GPHXDAO_ADDRESS, GPHXDAO_ABI, signer));
        }
    }, []);

    

    useEffect(() => {
        async function fetchAdminAddress() {
            if (DAOContract.contract) {
                try {
                    const fetchedAdminAddress = await DAOContract.contract.call("owner");
                    setAdminAddress(fetchedAdminAddress);
                } catch (error) {
                    console.error("Error fetching admin address:", error);
                }
            }
        }
        fetchAdminAddress();
    }, [DAOContract]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdowns(currentCountdowns => {
                const newCountdowns = { ...currentCountdowns };
                for (const proposalId in newCountdowns) {
                    const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
                    if (newCountdowns[proposalId].endTimestamp > currentTime) {
                        newCountdowns[proposalId].remainingTime = newCountdowns[proposalId].endTimestamp - currentTime;
                    } else {
                        newCountdowns[proposalId].remainingTime = 0;
                    }
                }
                return newCountdowns;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const newCountdowns = {};
        proposals.forEach(proposal => {
            const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
            const remainingSeconds = proposal.endTimestamp - currentTime;
            newCountdowns[proposal.proposalId] = {
                endTimestamp: proposal.endTimestamp,
                remainingTime: Math.max(0, remainingSeconds),
            };
        });
        setCountdowns(newCountdowns);
    }, [proposals]);
    async function fetchTokenBalance() {
        console.log("userAddress",userAddress)
        if (tokenContract.contract && userAddress) {
            try {
                const balanceWei = await tokenContract.contract.call("balanceOf",[userAddress]);
                setTokenBalance(ethers.utils.formatEther(balanceWei));
            } catch (error) {
                console.error("Error fetching token balance:", error);
            }
        }
    }
    useEffect(() => {
        
        fetchTokenBalance();
    }, [tokenContract, userAddress]);

    

  useEffect(() => {
    let isSubscribed = true;
  
    const fetchAllProposals = async () => {
      if (DAOContract.contract && isSubscribed) {
        try {
          const fetchedProposals = await DAOContract.contract.call("getAllProposals");
          const modifiedProposals = fetchedProposals.map(proposal => ({
            ...proposal,
            proposalId: proposal.proposalId.toNumber(),
            noVotes: proposal.noVotes.toString(),
            endTimestamp: proposal.endTimestamp.toNumber(),
            yesVotes: proposal.yesVotes.toString(),
          }));
  
          if (isSubscribed) {
            setProposals(modifiedProposals);
            console.log("Fetched Proposals: ", modifiedProposals);
          }
        } catch (error) {
          console.error("Error fetching proposals:", error);
        }
      }
    };
  
    fetchAllProposals();
  
    return () => {
      isSubscribed = false;
    };
  }, [DAOContract]);

    const isAdmin = userAddress && adminAddress && userAddress.toLowerCase() === adminAddress.toLowerCase();

    const createProposal = async (description) => {
        if (isAdmin && DAOContract.contract && description.trim() !== "") {
            try {
                const createTx = await DAOContract.contract.call("createProposal",[description]);
                await createTx.wait();
                alert('Proposal created successfully.');
            } catch (error) {
                console.error("Error creating proposal:", error);
                alert('Proposal Created Successfully.');
            }
        }
    };

    const approveTokens = async (amount) => {
        console.log("tokenContract",tokenContract);
      if (tokenContract && userAddress) {
          try {
              const amountWei = ethers.utils.parseUnits(amount, "ether");
              const approveTx = await tokenContract.contract.call("approve",[GPHXDAO_ADDRESS,amountWei], { from: userAddress });
              console.log("approveTx",approveTx);
              //await approveTx.wait();
              alert('Approval successful.');
          } catch (error) {
              console.error("Error approving tokens:", error);
              alert('Failed to approve tokens.');
          }
      }
  };

  const voteOnProposal = async (proposalId, voteAmount, voteOption) => {
    console.log(DAOContract);
    if (userAddress && voteAmount && voteOption && !isNaN(proposalId) && proposalId >= 0) {
        try {
            const stakeAmountWei = ethers.utils.parseUnits(voteAmount, "ether");
            const voteOptionUint8 = voteOption === "Yes" ? 1 : voteOption === "No" ? 2 : 0;

            // Approve tokens for the contract
            await approveTokens(voteAmount);

            const gasLimit = ethers.BigNumber.from("1000000");

            const voteTx = await DAOContract.contract.call("vote", [proposalId, stakeAmountWei, voteOptionUint8], {
                gasLimit: gasLimit
            });

            // Fetch the new token balance and update the state
            fetchTokenBalance();

            alert('Voted successfully.');
        } catch (error) {
            console.error("Error voting on proposal:", error);
            alert('Failed to vote on proposal: ' + error.message);
        }
    } else {
        console.error("Invalid proposal ID, stake amount, or vote option");
        alert('Invalid proposal ID, stake amount, or vote option.');
    }
};

const getProposalStatus = async (proposalId) => {
    if (DAOContract.contract && proposalId) {
        try {
            const status = await DAOContract.contract.call("checkProposalStatus",[proposalId]);
            return status;
        } catch (error) {
            console.error("Error fetching proposal status:", error);
            return null; // or handle the error as appropriate
        }
    }
    return null;
};

const unstakeTokens = async () => {
    if (DAOContract.contract && userAddress && unstakeAmount && unstakeProposalId && !isNaN(unstakeAmount) && unstakeAmount > 0) {
        try {
            const proposalStatus = await getProposalStatus(unstakeProposalId);
            if (proposalStatus !== "Inactive") { // Assuming "Inactive" is the status for a proposal that is no longer active
                alert(`Cannot unstake: Proposal is still ${proposalStatus}.`);
                return;
            }

            const amountWei = ethers.utils.parseUnits(unstakeAmount, "ether");
            const unstakeTx = await DAOContract.contract.call("unstake", [unstakeProposalId, amountWei]);

            // Fetch the new token balance and update the state
            fetchTokenBalance();

            alert('Unstaked successfully.');
        } catch (error) {
            console.error("Error unstaking tokens:", error);
            alert('Failed to unstake tokens.');
        }
    } else {
        console.error("Invalid unstake amount or proposal ID");
        alert('Invalid unstake amount or proposal ID.');
    }
};
const updateSpecificProposalDuration = async () => {
    if (isAdmin && DAOContract.contract && updateProposalId && updateDuration) {
        try {
            const proposalId = ethers.BigNumber.from(updateProposalId);
            const durationInMinutes = ethers.utils.parseUnits(updateDuration, "wei");
            const updateTx = await DAOContract.contract.call("updateSpecificProposalDuration",[proposalId, durationInMinutes]);
            await updateTx.wait();
            alert('Specific proposal duration updated successfully.');
        } catch (error) {
            console.error("Error updating specific proposal duration:", error);
            alert('Failed to update specific proposal duration.');
        }
    }
};

const closeProposal = async () => {
    if (isAdmin && DAOContract.contract && selectedProposalId.trim() !== "") {
        try {
            const proposalId = ethers.BigNumber.from(selectedProposalId);
            const closeTx = await DAOContract.contract.call("closeProposal",[proposalId]);
            await closeTx.wait();
            alert('Proposal closed successfully.');
        } catch (error) {
            console.error("Error closing proposal:", error);
            alert('Failed to close proposal.');
        }
    }
};

const fetchStakedTokens = async () => {
    if (DAOContract.contract && addressToCheck && proposalIdToCheck) {
        try {
            const stakedAmount = await DAOContract.contract.call("getStakedTokensByProposal",[addressToCheck, proposalIdToCheck]);
            setStakedTokens(ethers.utils.formatEther(stakedAmount));
        } catch (error) {
            console.error("Error fetching staked tokens:", error);
            setStakedTokens('Error in fetching tokens');
        }
    }
};

return (
    <main className={styles.homePage} style={{ backgroundImage: `url(${GOLD})` }}>
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.daoConnect}>
                    <ConnectWallet />
                </div>

                <Link to="/" className={styles.homePageLink}>
                    <img src={GPHX} alt="GPHX Logo" className={styles.logo} />
                </Link>
                <h1>Governance Phoenix Dao</h1>
            </div>

            <div className={styles.tokenBalance}>
                <h2>GPHX Balance: {tokenBalance}</h2>
            </div>

            <div className={styles.filterButtons}>
                <button
                    className={showActiveProposals ? "active" : ""}
                    onClick={() => setShowActiveProposals(true)}
                >
                    Active Proposals
                </button>
                <button
                    className={!showActiveProposals ? "active" : ""}
                    onClick={() => setShowActiveProposals(false)}
                >
                    Inactive Proposals
                </button>
            </div>

            <div className={styles.proposals}>
                {filteredAndSortedProposals.length === 0 ? (
                    <p>No proposals available.</p>
                ) : (
                    filteredAndSortedProposals.map((proposal) => (
                        <ProposalComponent
                          key={proposal.proposalId}
                          proposal={proposal}
                          voteOnProposal={voteOnProposal}
                          countdowns={countdowns}
                        />
                    ))
                )}
            </div>

            <div>
                <h2>Unstake Tokens Once Proposal Has Ended</h2>
                <input
                    type="text"
                    placeholder="Proposal ID"
                    value={unstakeProposalId}
                    onChange={(e) => setUnstakeProposalId(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Unstake amount (GPHX)"
                    value={unstakeAmount}
                    onChange={(e) => setUnstakeAmount(e.target.value)}
                />
                <button onClick={() => unstakeTokens()}>Unstake</button>
            </div>

                {/* Section to Fetch Staked Tokens for an Address and Proposal ID */}
                <div className={styles.stakedTokensSection}>
                <div className={styles.stakedTokensHeader}>
                    <h2>GPHX Locked</h2>
                {stakedTokens && <p className={styles.stakedTokensBalance}>GPHX Locked: {stakedTokens}</p>}
            </div>
                    <input
                    type="text"
                    placeholder="Enter wallet address"
                    value={addressToCheck}
                    onChange={(e) => setAddressToCheck(e.target.value)}
            />
                    <input
                    type="text"
                    placeholder="Enter proposal ID"
                    value={proposalIdToCheck}
                    onChange={(e) => setProposalIdToCheck(e.target.value)}
            />
                <button onClick={fetchStakedTokens}>Fetch Tokens</button>
                </div>

            {isAdmin && (
              <div>
                <h2>Admin Panel</h2>

                {/* Code to create a new proposal */}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    createProposal(newProposalDescription);
                    setNewProposalDescription("");
                  }}
                >
                  <input
                    type="text"
                    value={newProposalDescription}
                    onChange={(e) => setNewProposalDescription(e.target.value)}
                    placeholder="Enter proposal description"
                  />
                  <button type="submit">Create Proposal</button>
                </form>

                {/* Input for setting new proposal duration */}
                <div>
                  <input
                    type="text"
                    value={updateProposalId}
                    onChange={(e) => setUpdateProposalId(e.target.value)}
                    placeholder="Enter proposal ID to update duration"
                  />
                  <input
                    type="text"
                    value={updateDuration}
                    onChange={(e) => setUpdateDuration(e.target.value)}
                    placeholder="Enter new duration (in minutes)"
                  />
                  <button onClick={updateSpecificProposalDuration}>
                    Update Specific Proposal Duration
                  </button>
                </div>

                {/* Input for closing a proposal */}
                <div>
                  <select
                    value={selectedProposalId}
                    onChange={(e) => setSelectedProposalId(e.target.value)}
                  >
                    <option value="">Select a proposal to close</option>
                    {proposals.map((p, i) => (
                      <option key={i} value={p.proposalId}>
                        Proposal {p.proposalId}
                      </option>
                    ))}
                  </select>
                  <button onClick={closeProposal}>Close Proposal</button>
                </div>
              </div>
            )}
        </div>
    </main>
);
}

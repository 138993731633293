import React, { useState, useEffect, useRef } from 'react';
import ChatABI from './Chat.json';
import { useAddress, ConnectWallet, useContract, useContractEvents } from "@thirdweb-dev/react";
import styles from './ChatRoom.module.css';
import EmojiPicker from 'emoji-picker-react';
import GPHX from './GPHX2.png';
import { Link } from 'react-router-dom';

const CHAT_CONTRACT_ADDRESS = "0x95BCdD2c47A67081421Aa4904C9727879905a16C";

const ChatRoom = () => {
    const [messages, setMessages] = useState([]);
    const [username, setUsername] = useState('');
    const [inputMessage, setInputMessage] = useState('');
    const [hasUsername, setHasUsername] = useState(false);
    const messagesEndRef = useRef(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [showWalletInfo, setShowWalletInfo] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const { contract } = useContract(CHAT_CONTRACT_ADDRESS, ChatABI);
    const userAddress = useAddress();
    const { data: eventsData } = useContractEvents(contract);
    
        const loadMessages = async () => {
            if (!contract) return;
    
            try {
                const messageCountResponse = await contract.call('getMessageCount');
                const messageCount = messageCountResponse.toNumber();
                console.log("Message Count:", messageCount);
    
                if (messageCount > 0) {
                    const messagesBatch = await contract.call('getMessages', [0, messageCount]);
                    console.log("Messages Batch:", messagesBatch);
    
                    const fetchedMessages = messagesBatch.map(msg => ({
                        ...msg,
                        username: msg.username || msg.sender
                    }));
                    setMessages(fetchedMessages);
                } else {
                    console.log("No messages to fetch.");
                }
            } catch (error) {
                console.error("Error fetching messages:", error);
            }
        };
    
        useEffect(() => {
            if (!contract || !userAddress) return;
        
            const loadUsername = async () => {
                try {
                    console.log("Fetching username for address:", userAddress);
                    const currentUsername = await contract.call('getUsername', [userAddress]);
                    setUsername(currentUsername || '');
                    setHasUsername(!!currentUsername);
                } catch (error) {
                    console.error("Error fetching username:", error);
                }
            };
        
            loadUsername();
            loadMessages();
        }, [contract, userAddress]);
    
        useEffect(() => {
            if (eventsData && eventsData.length > 0) {
                loadMessages();
            }
        }, [eventsData]);
    
        useEffect(() => {
            if (userAddress) {
                setShowWalletInfo(false);
            }
        }, [userAddress]);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        setIsAtBottom(bottom);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
    
        const dateString = date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
    
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const hoursString = hours.toString();
        const minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
    
        return {
            date: dateString,
            time: `${hoursString}:${minutesString} ${ampm}`
        };
    };

    const sendMessage = async () => {
        if (!inputMessage.trim()) return;

        try {
            await contract.call('sendMessage', [inputMessage]);
            setInputMessage('');
            setShowEmojiPicker(false);
        } catch (error) {
            console.error("Error sending message:", error);
            alert("Failed to send message: " + error.message);
        }
    };
    
    const updateUsername = async (newUsername) => {
        if (!newUsername.trim()) return;
    
        try {
            await contract.call('setUsername', [newUsername]);
            setUsername(newUsername);
            setHasUsername(true);
        } catch (error) {
            console.error("Error updating username:", error);
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const linkify = (text) => {
        // Check if text is undefined or null
        if (!text) {
            return '';
        }
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
    };

    

    const handleEmojiClick = (emojiObject) => {
        setInputMessage((prevInput) => prevInput + emojiObject.emoji);
    };

    const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
        if (!showEmojiPicker) setShowWalletInfo(false);
    };

    const chatMessagesHeight = showEmojiPicker ? 'calc(100% - 0px)' : 'calc(100% - 60px)';

    const renderWalletInfo = () => {
        if (showWalletInfo) {
            return <div className={styles.walletInfo}><ConnectWallet /></div>;
        }
        return null;
    };

    return (
        <div className={styles.chatRoom} style={{ height: showEmojiPicker ? 'calc(100vh - 0px)' : '100vh' }}>
            <div className={styles.chatMessages} onScroll={handleScroll} style={{ height: chatMessagesHeight }}>
                {messages.map((msg, index) => (
                    <div key={index} className={styles.message}>
                        <div className={styles.messageContent}>
                            <span className={styles.messageSender}>
                                {msg.username && msg.username !== "0x0000000000000000000000000000000000000000" ? msg.username : ''}
                            </span>
                            <span 
                                className={styles.messageText} 
                                dangerouslySetInnerHTML={{ __html: linkify(msg.text) }} 
                            />
                        </div>
                        <div className={styles.messageTimestamp}>
                            <span className={styles.messageTime}>{formatDate(msg.timestamp).time}</span>
                            <br />
                            {formatDate(msg.timestamp).date}
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
    
            {!isAtBottom && (
                <scrollbutton onClick={scrollToBottom} className={styles.scrollToBottom}>🔽</scrollbutton>
            )}
    
                {showEmojiPicker && (
                    <div className={styles.emojiPicker}>
                        <EmojiPicker onEmojiClick={handleEmojiClick} />
                    </div>
                )}
    
            {!hasUsername && (
                <div className={styles.usernameInput}>
                    <input 
                        type="text" 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)} 
                        placeholder="Set a username"
                    />
                    <button onClick={() => updateUsername(username)}>Set Username</button>
                </div>
            )}
    
            {/* Rearrange the order for mobile displays */}
            <div className={styles.chatInput}>
            <input
                type="text"
                value={inputMessage}
                onChange={(e) => {
                    setInputMessage(e.target.value);
                    if (showEmojiPicker) {
                        setShowEmojiPicker(false); // Hide the emoji picker when typing starts
                    }
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        sendMessage();
                    }
                }}
                placeholder="Type a message..."
            />
                <Link to="/dao" className={styles.goToDao}>
                    <img src={GPHX} alt="GPHX Logo" className={styles.daoLogo} />
                </Link>
            <div className={styles.buttonsContainer}>
                <button onClick={sendMessage}>Send</button>
                <button onClick={toggleEmojiPicker}>👾</button>
                <button onClick={() => setShowWalletInfo(!showWalletInfo)}>
                    {userAddress ? 'Connected' : 'Connect'}</button>
            </div>
        </div>
    
            {renderWalletInfo()}
        </div>
    );
};

export default ChatRoom;

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import "./styles/globals.css";
import { Phoenix } from "@thirdweb-dev/chains";

const activeChain = Phoenix;

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ThirdwebProvider
      activeChain={activeChain}
      clientId={"aee1cac93e3b588aebf9a1ca0e819c83"}
      supportedChains={[Phoenix]}
      switchToActiveChain={true}
    >
      <App />
    </ThirdwebProvider>
  </React.StrictMode>
);

reportWebVitals();
